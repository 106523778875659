import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { puts } from 'util';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { PostServiceService } from '../services/post-service.service'
import { FirebaseStorage } from 'angularfire2';
import { ImageuploadService } from '../services/imageupload.service';
import { NavigationEnd, Router } from '@angular/router';

import { PostlistComponent } from '../postlist/postlist.component';

@Component({
  selector: 'app-addpost',
  templateUrl: './addpost.component.html',
  styleUrls: ['./addpost.component.scss']
})
export class AddpostComponent implements OnInit {
  uploadPercent: Observable<number>
  downloadURL: Observable<string>
  content: string
  image: string
  title: string
  randomid;
  subtitle;
  comments;
  hashtags;


  constructor(private storage: AngularFireStorage, private postService: PostServiceService, private router: Router){

  }

  ngOnInit() {
  }

  // preview(files) {
  //   if (files.length === 0) {
  //     return;
  //   }
  //   const mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.message = 'Only images are supported.';
  //     return;
  //   }
  //   const reader = new FileReader();
  //   this.imagePath = files;
  //   reader.readAsDataURL(files[0]);
  //   this.image = files[0];
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //   };
  // }

  uploadImage(event) {
    const file = event.target.files[0];
    this.randomid = this.generateUUID();
    const path = 'images/' + this.randomid;
    if (file.type.split('/')[0] !== 'image') {
      return alert('only image files')
    } else {
      const file = event.target.files[0];
      const fileRef: AngularFireStorageReference = this.storage.ref(
        path
      );
      const task: AngularFireUploadTask = this.storage.upload(
        path,
        file
      );
      task
          .snapshotChanges()
          .pipe(
          finalize(() => {
              fileRef.getDownloadURL().subscribe(downloadURL => {
                  console.log(downloadURL);
                  this.image=downloadURL;
              });
        })
    )
    .subscribe();
      this.uploadPercent = task.percentageChanges();
      console.log('Image Uploaded!');
    
};
  }

  createPost() {
    const newPost = {
      title: this.title,
      subtitle: this.subtitle,
      hashtags: this.hashtags,
      comments: this.comments,
      image: this.image || null
    };

    // this.randomid = this.generateUUID();
    // const filePath = 'images/' + this.randomid;
    // const fileRef = this.storage.ref(filePath);
    // const task = this.storage.upload(filePath, this.image)

    // const ref = this.storage.ref('images/' + this.randomid);
    // console.log(ref);

    this.postService.addPostToDataBase(newPost)
      .then(res => {
        console.log('Success?');
        alert('Image uploaded successfully.');
      });

this.router.navigate(['/']);

  }
  generateUUID(): any { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}