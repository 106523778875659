import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddpostComponent } from './addpost/addpost.component';
import { PostlistComponent } from './postlist/postlist.component';


const routes: Routes = [
  {path: 'addpost', component: AddpostComponent},
  {path: '', component: PostlistComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
