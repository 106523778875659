// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
  apiKey: "AIzaSyCLa3gu5zoaLLVBVqYD_lQnTGwlPufV1bQ",
  authDomain: "tylerchildsphoto-30940.firebaseapp.com",
  databaseURL: "https://tylerchildsphoto-30940.firebaseio.com",
  projectId: "tylerchildsphoto-30940",
  storageBucket: "tylerchildsphoto-30940.appspot.com",
  messagingSenderId: "344766501862",
  appId: "1:344766501862:web:59d7b43007458138043c27",
  measurementId: "G-SF1TTH17FZ"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
