import { Component, OnInit } from '@angular/core';
import { PostServiceService } from '../services/post-service.service';
import { AngularFireStorage, createStorageRef } from 'angularfire2/storage'
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import {MaterialModule} from '../material/material.module'

@Component({
  selector: 'app-postlist',
  templateUrl: './postlist.component.html',
  styleUrls: ['./postlist.component.scss']
})
export class PostlistComponent implements OnInit {
  imagesource;
  currentPosts;
  profileUrl: Observable<string | null>;
  postarray;
  isFullScreen: boolean = false;

  constructor(private postService: PostServiceService, private storage: AngularFireStorage, material: MaterialModule) {
    
  }
  ngOnInit() {
    this.getCurrentPosts();
    
  }

  getCurrentPosts() {
  this.postService.getPostFromDataBase().subscribe(res => this.currentPosts = res);
  }

changeView(){
  if(!this.isFullScreen){
  document.getElementById("cardGrid").style.cssText='grid-template-columns: 100%;'
this.isFullScreen = true;  
}

else{
document.getElementById("cardGrid").style.cssText='grid-template-columns: 33% 33% 33%;'
this.isFullScreen = false;
}
}


  }











 