import { Injectable } from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostServiceService {

  constructor(private firestore: AngularFirestore) { }


  addPostToDataBase(title){
    return new Promise<any>((resolve, reject) =>{
      this.firestore.collection('postFeed').add(title)
          .then(res => {console.log('Success')}, err => reject(err));
    
  });
}

getPostFromDataBase(){
 return this.firestore.collection('postFeed').snapshotChanges();
    }



}
